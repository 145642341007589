@import "~react-vis/dist/style";

.submit {
  background-color: lightblue;
}

.cancel {
  background-color: darkorange
}

.my-custom-scrollbar-small {
  position: relative;
  height:15rem;
  overflow: auto;
}

.my-custom-scrollbar {
  position: relative;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block
}

.special-button {
  font-weight: bold;
}

.detail-label {
  font-weight: bold;
  font-size: larger;
}

.center {
  text-align: center;
}

.white {
  color: white;
}

.detail-block {
  display: block;
  block-size:10rem;
  border-style: solid;
}

.chart {
  margin-top: 1rem;
  border-style: solid;
  border-color:darkgrey;
  background-color: rgb(255, 255, 255);
}

.calendar {
  font-size: smaller;
}

.banner {
  display: block;
  block-size: 5rem;
  padding-top: 1rem;
  align-content: center;
  background-color: black;
  font-weight: bold;
  color: white;
  text-align: center;
}